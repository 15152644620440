<template>
  <div class="px-2 px-md-8">
    <v-row>
      <v-col class="grow">
        <v-autocomplete
          :items="products"
          item-text="title"
          item-value="uuid"
          prepend-icon="mdi-magnify"
          placeholder="Search product"
          clearable
          @change="show_product_location"
          ></v-autocomplete>
      </v-col>
      <v-col class="shrink d-flex justify-space-between">
          <v-dialog
            v-model="dialog"
            fullscreen
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                >
                {{ get_unprinted_shelflabels.length ? `${get_unprinted_shelflabels.length} unprinted shelf labels` : 'Shelf labels' }}
              </v-btn>
            </template>
            <ShelfLabelsDialog 
              :products="get_unprinted_shelflabels"
              @close="dialog = false"
              />
          </v-dialog>
          <!-- <v-btn
            color="primary"
            class="ml-2"
            @click="toggleViewMode"
            >
            {{ viewMode === 'list' ? 'Canvas View' : 'List View' }}
          </v-btn> -->
      </v-col>
    </v-row>
    
    <!-- List View Mode -->
    <div v-if="viewMode === 'list'">
      <v-row
        v-drag-and-drop:options="options"
        class="warehouse-wrapper"
        @reordered="reordered"
        >
        <v-col 
          v-for="(shelve, index) in warehouse.shelves" 
          :key="`shelve-${index}`" 
          class="shelve-wrapper"
          :data-id="index"
          cols="3"
          md="2"
          xl="1"
          >
          <div class="shelve text-center" :class="highlighted === index.toString() ? 'highlight' : ''" @click="$router.push(`warehouse/shelve/${index + 1}`)">
            <div class="shelf_number">
              <div class="shelf_text">
                {{ shelve.name || index + 1 }}
              </div>
            </div>
            <div v-for="(shelf, i) in warehouse.shelves[index].shelfs" 
              :key="`shelf-${i}`" 
              :style="{height: `${100 / warehouse.shelves[index].shelfs.length}%`}"
              class="shelf"
              >
                <!-- SHELF  -->
            </div>
          </div>
            <div class="buttons_wrapper">
              <v-btn fab x-small color="primary" class="badge-plus" @click="addShelve(index)">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-btn fab x-small color="secondary" :disabled="warehouse.shelves.length === 1" class="badge-minus" @click="removeShelve(index)">
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
              <v-btn fab x-small color="grey" :disabled="warehouse.shelves.length === 1" class="badge-drag" >
                <v-icon dark>
                  mdi-drag
                </v-icon>
              </v-btn>
            </div>
        </v-col>
      </v-row>
    </div>
    
    <!-- Canvas View Mode -->
    <div v-else class="warehouse-canvas-container">
      <v-card class="warehouse-canvas-toolbar pa-2 mb-2">
        <v-btn color="primary" small @click="addShelveToCanvas">
          <v-icon left>mdi-plus</v-icon>
          Add Shelf
        </v-btn>
        
        <v-spacer></v-spacer>
        
        <!-- Canvas Options -->
        <v-btn-toggle v-model="snapToGrid" dense class="mr-2">
          <v-btn small :value="true" title="Snap to grid">
            <v-icon>mdi-grid</v-icon>
          </v-btn>
          <!-- <v-btn small :value="false" title="Free positioning">
            <v-icon>mdi-gesture</v-icon>
          </v-btn> -->
        </v-btn-toggle>
        
        <!-- Zoom Controls -->
        <v-btn-toggle dense class="mr-2">
          <v-btn small @click="zoomOut">
            <v-icon>mdi-magnify-minus-outline</v-icon>
          </v-btn>
          <v-btn small @click="resetZoom">
            {{ Math.round(zoomLevel * 100) }}%
          </v-btn>
          <v-btn small @click="zoomIn">
            <v-icon>mdi-magnify-plus-outline</v-icon>
          </v-btn>
        </v-btn-toggle>
        
        <!-- Walking Path Toggle -->
        <v-btn-toggle v-model="showWalkingPath" dense class="mr-2">
          <v-btn small :value="true" title="Show walking path">
            <v-icon>mdi-map-marker-path</v-icon>
          </v-btn>
          <!-- <v-btn small :value="false" title="Hide walking path">
            <v-icon>mdi-map-marker-off</v-icon>
          </v-btn> -->
        </v-btn-toggle>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              icon 
              small 
              v-bind="attrs"
              v-on="on"
              @click="showWalkingOrderHelp = !showWalkingOrderHelp"
            >
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>Walking Order Help</span>
        </v-tooltip>
      </v-card>
      
      <v-alert
        v-if="showWalkingOrderHelp"
        type="info"
        dismissible
        @input="showWalkingOrderHelp = false"
        class="mb-2"
      >
        <p>The number on each shelf represents its position in the walking order.</p>
        <p>Click on a shelf to select it, then use the arrows to change its position in the walking order.</p>
        <p>Drag shelves to arrange them into aisles. Toggle grid snapping to help align shelves perfectly.</p>
      </v-alert>
      
      <div 
        class="warehouse-canvas-outer"
        @wheel.prevent="handleZoom"
        @mousedown="startPan"
        @mousemove="onPan"
        @mouseup="stopPan"
        @mouseleave="stopPan"
      >
        <div 
          ref="canvasContainer" 
          class="warehouse-canvas"
          :style="canvasStyle"
          :class="{ 'show-aisle-grid': showAisleGrid }"
        >
          <!-- Aisle grid visualization -->
          <div class="aisle-grid" v-if="showAisleGrid">
            <div 
              v-for="i in 20" 
              :key="`h-aisle-${i}`" 
              class="aisle-line horizontal"
              :style="{ top: `${i * aisleWidth}px` }"
            ></div>
            <div 
              v-for="i in 20" 
              :key="`v-aisle-${i}`" 
              class="aisle-line vertical"
              :style="{ left: `${i * aisleWidth}px` }"
            ></div>
          </div>
          
          <!-- Walking path visualization -->
          <svg class="walking-path" v-if="showWalkingPath">
            <path :d="getWalkingPathData()" stroke="#1976d2" stroke-width="2" fill="none" stroke-dasharray="5,5" />
          </svg>
          
          <div 
            v-for="(shelve, index) in warehouse.shelves" 
            :key="`canvas-shelve-${index}`"
            class="canvas-shelve"
            :class="{ 
              'selected': selectedShelveIndex === index,
              'highlight': highlighted === index.toString(),
              'dragging': dragging && selectedShelveIndex === index
            }"
            :style="getShelveStyle(shelve, index)"
            @mousedown.stop="selectShelve(index, $event)"
            @click.stop="onShelveClick(index)"
          >
            <div class="canvas-shelve-index">{{ index + 1 }}</div>
            <v-btn 
              class="canvas-shelve-name" 
              text
              @click.stop="$router.push(`warehouse/shelve/${index + 1}`)"
              >{{ shelve.name || `Shelf ${index + 1}` }}</v-btn>
            <div class="canvas-shelve-controls" v-if="selectedShelveIndex === index">
              <v-btn icon x-small @click.stop="moveInWalkingOrder(index, -1)">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn icon x-small @click.stop="moveInWalkingOrder(index, 1)">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
              <v-btn icon x-small @click.stop="rotateShelve(index)">
                <v-icon>mdi-rotate-left</v-icon>
              </v-btn>
              <v-btn icon x-small color="error" @click.stop="removeShelve(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { uid } from 'uid'
import { VueDraggableDirective } from 'vue-draggable'

import ShelfLabelsDialog from '@/components/ShelfLabelsDialog'

export default {
  directives: {
    dragAndDrop: VueDraggableDirective,
  },
  components: {
    ShelfLabelsDialog,
  },
  data() {
    return {
      loading: true,
      search: '',
      highlighted: '',
      dialog: false,
      viewMode: 'canvas', // 'list' or 'canvas'
      options: { 
        draggableSelector: '.shelve-wrapper',
        dropzoneSelector: '.warehouse-wrapper',
      },
      // Canvas view properties
      selectedShelveIndex: null,
      dragging: false,
      dragStartX: 0,
      dragStartY: 0,
      dragOffsetX: 0,
      dragOffsetY: 0,
      showWalkingOrderHelp: false,
      // Zoom and pan properties
      zoomLevel: 1,
      panOffsetX: 0,
      panOffsetY: 0,
      isPanning: false,
      panStartX: 0,
      panStartY: 0,
      // Aisle visualization
      showAisleGrid: true,
      aisleWidth: 75, // Width of aisles in pixels
      snapToGrid: true, // Whether to snap shelves to grid
      // Walking path visualization
      showWalkingPath: true, // Show the walking path between shelves
    }
  },
  computed: {
    ...mapState({
      warehouse: state => state.warehouse,
      products: state => state.product.products,
    }),
    ...mapGetters({
      get_product_location_by_uuid: 'warehouse/get_product_location_by_uuid',
      get_plan: 'warehouse/get_plan',
      get_unprinted_shelflabels: 'warehouse/get_unprinted_shelflabels'
    }),
    canvasStyle() {
      return {
        transform: `scale(${this.zoomLevel}) translate(${this.panOffsetX}px, ${this.panOffsetY}px)`,
        transformOrigin: '0 0',
      }
    }
  },
  methods: {
    toggleViewMode() {
      this.viewMode = this.viewMode === 'list' ? 'canvas' : 'list'
      this.selectedShelveIndex = null
      
      // Reset zoom and pan when switching to canvas view
      if (this.viewMode === 'canvas') {
        this.resetZoom()
        this.initializeCanvasPositions()
      }
    },
    initializeCanvasPositions() {
      // Set default positions for shelves that don't have position data
      const shelvesWithPositions = this.warehouse.shelves.filter(shelve => 
        shelve.position && typeof shelve.position.x === 'number' && typeof shelve.position.y === 'number'
      )
      
      if (shelvesWithPositions.length < this.warehouse.shelves.length) {
        // Calculate grid layout for shelves without positions
        const shelvesToPosition = this.warehouse.shelves.length - shelvesWithPositions.length
        const cols = Math.ceil(Math.sqrt(shelvesToPosition))
        const spacing = 180
        
        let positionedCount = 0
        
        this.warehouse.shelves.forEach((shelve, index) => {
          if (!shelve.position || typeof shelve.position.x !== 'number' || typeof shelve.position.y !== 'number') {
            const col = positionedCount % cols
            const row = Math.floor(positionedCount / cols)
            
            // Set default position
            this.$set(this.warehouse.shelves[index], 'position', {
              x: 50 + col * spacing,
              y: 50 + row * spacing
            })
            
            positionedCount++
          }
        })
        
        // Mark as changed to enable saving
        this.$store.commit('warehouse/CHANGE_SHELF', 0)
      }
    },
    getShelveStyle(shelve, index) {
      const position = shelve.position || { x: 50, y: 50 + index * 120 }
      const rotation = shelve.rotation || 0;
      
      // Adjust dimensions based on rotation
      const isVertical = rotation % 180 !== 0;
      
      return {
        left: `${position.x}px`,
        top: `${position.y}px`,
        // transform: `rotate(${rotation}deg)`,
        width: isVertical ? '75px' : '150px',
        height: isVertical ? '150px' : '75px',
        '--shelf-rotation': `${rotation}deg`, // Set CSS variable for counter-rotation
      }
    },
    
    rotateShelve(index) {
      const shelve = this.warehouse.shelves[index];
      // Initialize rotation if it doesn't exist
      if (!shelve.rotation) {
        this.$set(shelve, 'rotation', 0);
      }
      
      // Rotate by 90 degrees (add 90 to current rotation)
      // const newRotation = (shelve.rotation + 90) % 360;
      const newRotation = shelve.rotation ? 0 : 90
      console.log(newRotation)
      this.$set(shelve, 'rotation', newRotation);
      
      // Mark as changed
      this.$store.commit('warehouse/CHANGE_SHELF', 0);
    },
    
    // Walking path visualization
    getWalkingPathData() {
      if (!this.warehouse.shelves.length) return ''
      
      // Generate SVG path data for the walking order
      let pathData = ''
      
      this.warehouse.shelves.forEach((shelve, index) => {
        const position = shelve.position || { x: 50, y: 50 + index * 120 }
        const rotation = shelve.rotation || 0;
        const isVertical = rotation % 180 !== 0;
        
        // Calculate connection point based on shelf orientation
        let connectionX, connectionY;
        
        if (isVertical) {
          // For vertical shelves (rotated 90 or 270 degrees)
          connectionX = position.x + 37.5; // Half of height (75px)
          connectionY = position.y + 75; // Half of width (150px)
        } else {
          // For horizontal shelves (rotated 0 or 180 degrees)
          connectionX = position.x + 75; // Half of width (150px)
          connectionY = position.y + 37.5; // Half of height (75px)
        }
        
        if (index === 0) {
          // Start path at the first shelf
          pathData = `M ${connectionX} ${connectionY}`
        } else {
          // Continue path to next shelf
          pathData += ` L ${connectionX} ${connectionY}`
        }
      })
      
      return pathData
    },
    
    // Zoom methods
    zoomIn() {
      this.zoomLevel = Math.min(this.zoomLevel + 0.1, 3)
    },
    
    zoomOut() {
      this.zoomLevel = Math.max(this.zoomLevel - 0.1, 0.3)
    },
    
    resetZoom() {
      this.zoomLevel = 1
      this.panOffsetX = 0
      this.panOffsetY = 0
    },
    
    handleZoom(event) {
      // Get mouse position relative to canvas
      const rect = event.currentTarget.getBoundingClientRect()
      const mouseX = event.clientX - rect.left
      const mouseY = event.clientY - rect.top
      
      // Calculate zoom factor based on wheel delta
      const delta = -Math.sign(event.deltaY) * 0.01
      const newZoom = Math.max(0.3, Math.min(3, this.zoomLevel + delta))
      
      // Calculate new pan offset to zoom toward mouse position
      if (delta !== 0) {
        const scale = newZoom / this.zoomLevel
        
        // Adjust pan offset to keep mouse position fixed during zoom
        this.panOffsetX = (mouseX / this.zoomLevel - this.panOffsetX) * (1 - scale) + this.panOffsetX
        this.panOffsetY = (mouseY / this.zoomLevel - this.panOffsetY) * (1 - scale) + this.panOffsetY
        
        this.zoomLevel = newZoom
      }
    },
    
    // Pan methods
    startPan(event) {
      // Only start panning if not clicking on a shelf
      if (event.target === event.currentTarget || event.target === this.$refs.canvasContainer) {
        this.isPanning = true
        this.panStartX = event.clientX
        this.panStartY = event.clientY
        event.preventDefault()
      }
    },
    
    onPan(event) {
      if (this.isPanning) {
        const dx = (event.clientX - this.panStartX) / this.zoomLevel
        const dy = (event.clientY - this.panStartY) / this.zoomLevel
        
        this.panOffsetX += dx
        this.panOffsetY += dy
        
        this.panStartX = event.clientX
        this.panStartY = event.clientY
      }
    },
    
    stopPan() {
      this.isPanning = false
    },
    
    selectShelve(index, event) {
      this.selectedShelveIndex = index
      
      // Prepare for dragging
      this.dragging = true
      this.dragStartX = event.clientX
      this.dragStartY = event.clientY
      
      // Calculate offset from the mouse position to the top-left corner of the shelve
      // This needs to account for the current zoom level
      const rect = event.target.getBoundingClientRect()
      this.dragOffsetX = (this.dragStartX - rect.left)
      this.dragOffsetY = (this.dragStartY - rect.top)
      
      // Add event listeners for drag and drop
      document.addEventListener('mousemove', this.onDrag)
      document.addEventListener('mouseup', this.stopDrag)
      
      event.preventDefault()
    },
    
    onDrag(event) {
      if (!this.dragging || this.selectedShelveIndex === null) return
      
      const canvasRect = this.$refs.canvasContainer.getBoundingClientRect()
      
      // Calculate the mouse position relative to the canvas, accounting for zoom and pan
      const mouseX = (event.clientX - canvasRect.left) / this.zoomLevel - this.panOffsetX
      const mouseY = (event.clientY - canvasRect.top) / this.zoomLevel - this.panOffsetY
      
      // Calculate the new position, accounting for the drag offset
      let newX = mouseX - this.dragOffsetX / this.zoomLevel
      let newY = mouseY - this.dragOffsetY / this.zoomLevel
      
      // Snap to grid if enabled
      if (this.snapToGrid) {
        // Snap to aisle grid
        const gridSize = this.aisleWidth
        newX = Math.round(newX / gridSize) * gridSize
        newY = Math.round(newY / gridSize) * gridSize
      }
      
      // Update the position of the selected shelve
      const shelve = this.warehouse.shelves[this.selectedShelveIndex]
      
      // Ensure we don't go out of bounds (using the larger canvas size)
      const maxX = 1900 // canvas width (2000) - shelve width (150) + some margin
      const maxY = 1900 // canvas height (2000) - shelve height (100) + some margin
      
      const x = Math.max(0, Math.min(newX, maxX))
      const y = Math.max(0, Math.min(newY, maxY))
      
      // Update position
      this.$set(shelve, 'position', { x, y })
      
      // Mark as changed
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
    },
    
    stopDrag() {
      this.dragging = false
      
      // Remove event listeners
      document.removeEventListener('mousemove', this.onDrag)
      document.removeEventListener('mouseup', this.stopDrag)
    },
    
    onShelveClick(index) {
      if (this.viewMode === 'canvas') {
        // Just select the shelve, don't navigate
        this.selectedShelveIndex = index
      } else {
        // In list view, navigate to the shelve detail
        this.$router.push(`warehouse/shelve/${index + 1}`)
      }
    },
    
    moveInWalkingOrder(index, direction) {
      // Move the shelve up or down in the walking order
      const newIndex = index + direction
      
      // Check bounds
      if (newIndex < 0 || newIndex >= this.warehouse.shelves.length) return
      
      // Swap shelves in the array (changes the walking order)
      const temp = this.warehouse.shelves[index]
      this.$set(this.warehouse.shelves, index, this.warehouse.shelves[newIndex])
      this.$set(this.warehouse.shelves, newIndex, temp)
      
      // Update selected index
      this.selectedShelveIndex = newIndex
      
      // Mark as changed
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
    },
    
    addShelveToCanvas() {
      if(this.warehouse.shelves.length >= this.get_plan.max_shelves) {
        return this.$store.commit('app/SET_SNACK_BAR', `Your current plan doesn't allow more shelves to be added. Upgrade to add more!`)
      }
      
      // Calculate position for the new shelve in the visible area of the canvas
      const canvasOuterRect = this.$refs.canvasContainer.parentElement.getBoundingClientRect()
      
      // Calculate the visible area in canvas coordinates (accounting for zoom and pan)
      const visibleLeft = -this.panOffsetX
      const visibleTop = -this.panOffsetY
      const visibleWidth = canvasOuterRect.width / this.zoomLevel
      const visibleHeight = canvasOuterRect.height / this.zoomLevel
      
      // Place the new shelf at a grid position if snap to grid is enabled
      let position
      if (this.snapToGrid) {
        const gridSize = this.aisleWidth
        position = {
          x: Math.round((visibleLeft + visibleWidth / 2) / gridSize) * gridSize,
          y: Math.round((visibleTop + visibleHeight / 2) / gridSize) * gridSize
        }
      } else {
        // Place randomly within visible area
        position = {
          x: visibleLeft + Math.random() * (visibleWidth - 150), // 150 is shelf width
          y: visibleTop + Math.random() * (visibleHeight - 100)  // 100 is shelf height
        }
      }
      
      // Ensure position is within canvas bounds
      position.x = Math.max(0, Math.min(position.x, 1900))
      position.y = Math.max(0, Math.min(position.y, 1900))
      
      // Add new shelve with position
      this.warehouse.shelves.push({
        id: uid(),
        name: this.warehouse.shelves.some(s => s.name) ? 
          `Nameless ${this.warehouse.shelves.filter(s => s.name?.includes('Nameless')).length}`
          : null,
        position,
        shelfs: [{ id: uid(), slots: [{ id: uid(), products: [{ uid: "", id: uid() }] }] }]
      })
      
      // Select the new shelve
      this.selectedShelveIndex = this.warehouse.shelves.length - 1
      
      // Mark as changed
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
      this.$store.commit('warehouse/SET_UNPRINTED_PAST', { shelve: { from: this.warehouse.shelves.length - 1 } })
    },
    
    // Original methods
    reordered({ detail }) {
      const id = +detail.ids[0]
      const { index } = detail
      if(id === index) return
      
      this.warehouse.shelves = this.warehouse.shelves.map((shelve, index) => ({
        ...shelve,
        name: shelve.name || (index + 1).toString(),
      }))
      const shelf = this.warehouse.shelves.splice(id, 1)
      this.warehouse.shelves.splice(index, 0, shelf[0])
      
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
    },
    
    addShelve(index) {
      if(this.warehouse.shelves.length >= this.get_plan.max_shelves) {
        return this.$store.commit('app/SET_SNACK_BAR', `Your current plan doesn't allow more shelves to be added. Upgrade to add more!`)
      }
      
      this.warehouse.shelves.splice(index + 1, 0, {
        id: uid(),
        name: this.warehouse.shelves.some(s => s.name) ? 
          `Nameless ${this.warehouse.shelves.filter(s => s.name?.includes('Nameless')).length}`
          : null,
        position: { x: 50, y: 50 + (index + 1) * 120 },
        shelfs: [{ id: uid(), slots: [{ id: uid(), products: [{ uid: "", id: uid() }] }] }]
      })
      
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
      this.$store.commit('warehouse/SET_UNPRINTED_PAST', { shelve: { from: index + 1 } })
    },
    
    removeShelve(index) {
      this.warehouse.shelves.splice(index, 1)
      
      if (this.selectedShelveIndex === index) {
        this.selectedShelveIndex = null
      } else if (this.selectedShelveIndex > index) {
        this.selectedShelveIndex--
      }
      
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
      this.$store.commit('warehouse/SET_UNPRINTED_PAST', { shelve: { from: index } })
    },
    
    show_product_location(uuid) {
      const position = this.get_product_location_by_uuid(uuid)
      if(!position) return
      
      this.highlighted = position.shelve.toString()
      
      if (this.viewMode === 'canvas') {
        // In canvas view, just highlight the shelve
        setTimeout(() => {
          this.highlighted = ''
        }, 2000)
      } else {
        // In list view, navigate to the shelve detail
        setTimeout(() => {
          this.highlighted = ''
          this.$router.push(`warehouse/shelve/${Number(position.shelve) + 1}?shelf=${position.shelf}&slot=${position.slot}&product=${position.product}`)
        }, 500)
      }
    }
  },
  mounted() {
    // Initialize canvas positions when component is mounted
    this.initializeCanvasPositions()
  }
}
</script>

<style lang="scss" scoped>
.shelve {
  border-left: 1px solid black;
  border-right: 1px solid black;
  min-height: 350px;
  width: 100%;
  max-width: 180px;
  display: inline-block;
}

.shelve.highlight {
  animation-duration: 0.5s;
  animation-name: highlight;
}

@keyframes highlight {
  0% { background-color: inherit; }
  50% { background-color: rgba(191, 191, 63, 0.32); }
  100% { background-color: inherit; }
}

.slot {
  position: inline-block;
  outline: 1px solid black;
  height: 100%;
}

.shelf_number {
  position: absolute;
  padding: 0px 10px;
}

.buttons_wrapper {
  display: inline;
  width: 0px;
  height: 0px;
  position: relative;
}

.badge-plus {
  position: absolute;
  left: -15px;
  top: -260px;
}

.badge-minus {
  position: absolute;
  left: -15px;
  top: -220px;
}

.badge-drag {
  position: absolute;
  left: -15px;
  top: -180px;
}

.shelf {
  cursor: pointer;
  border-bottom: 1px solid black;
  position: relative;
}

/* Canvas view styles */
.warehouse-canvas-container {
  position: relative;
  width: 100%;
}

.warehouse-canvas-outer {
  position: relative;
  width: 100%;
  height: 600px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  overflow: hidden;
  cursor: grab;
}

.warehouse-canvas-outer:active {
  cursor: grabbing;
}

.warehouse-canvas {
  position: absolute;
  width: 2000px;
  height: 2000px;
  background-image: 
    linear-gradient(rgba(200, 200, 200, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(200, 200, 200, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: 0 0;
  transition: transform 0.1s ease-out;
}

.warehouse-canvas-toolbar {
  display: flex;
  align-items: center;
}

.canvas-shelve {
  position: absolute;
  width: 150px;
  height: 75px;
  background: linear-gradient(90deg, rgba(255,255,255,1), rgb(218, 218, 218));
  border: 1px solid black;
  border-radius: 5px;
  cursor: move;
  user-select: none;
  z-index: 1;
  transition: transform 0.3s ease-out, box-shadow 0.1s ease-out;
  transform-origin: center center;
}

.canvas-shelve.selected {
  border: 2px solid #1976d2;
  z-index: 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.canvas-shelve.dragging {
  opacity: 0.8;
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.canvas-shelve.highlight {
  animation: borderflash 1s 3;
}

@keyframes borderflash {
  0% { border-color: black; }
  50% { border-color: rgba(191, 191, 63, 1); border-width: 3px; }
  100% { border-color: black; }
}

.canvas-shelve-index {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #1976d2;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  /* Ensure index number doesn't rotate with the shelf */
  transform: rotate(calc(-1 * var(--shelf-rotation, 0deg)));
}

.canvas-shelve-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(calc(-1 * var(--shelf-rotation, 0deg)));
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 90%;
  height: 30px;
  /* Ensure text doesn't rotate with the shelf */
  backface-visibility: hidden;
}

.canvas-shelve-controls {
  position: absolute;
  bottom: -30px;
  // left: 50%;
  // transform: translateX(-50%) rotate(calc(-1 * var(--shelf-rotation, 0deg)));
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
  display: flex;
  z-index: 3;
}

.aisle-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.aisle-line {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
}

.aisle-line.horizontal {
  width: 100%;
  height: 1px;
}

.aisle-line.vertical {
  width: 1px;
  height: 100%;
}

.walking-path {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}
</style>
